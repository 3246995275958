import { Combobox } from '@headlessui/react'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useEffect, useRef } from 'react'
import { HiCheck, HiChevronUpDown } from 'react-icons/hi2'
import AssetThumbnail from 'components/DetailView/Vault/AssetThumbnail'
import Loading from 'components/Loading'
import { formatAmount } from 'helpers/format'
import { classNames } from 'helpers/utilities'
import useSelectAsset from './SelectAsset.hooks'
import type { AccountAsset, AccountAssetsResponse } from 'types/node'

export interface SelectAssetProps {
  sender: string | undefined
  selectedAsset: AccountAsset | undefined
  setSelectedAsset: (asset: AccountAsset) => void
}

export default function SelectAsset(props: SelectAssetProps) {
  const { selectedAsset } = props

  const { infiniteQuery, setQuery, handleSelectAsset, showAssets, showNoResults, showSpinner } =
    useSelectAsset(props)

  return (
    <Combobox as="div" value={selectedAsset} onChange={handleSelectAsset}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
        Asset
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-2 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-gray-100 dark:ring-transparent dark:focus:ring-brand-500 dark:caret-gray-400"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(asset: AccountAsset) => asset?.name || ''}
          autoComplete="new-password"
          spellCheck="false"
        />

        {showSpinner && (
          <div className="absolute inset-y-0 right-12 flex items-center">
            <Loading color="text-gray-900 dark:text-white/40" size="h-5 w-5" />
          </div>
        )}

        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <HiChevronUpDown
            className="h-5 w-5 text-gray-400 dark:text-gray-600"
            aria-hidden="true"
          />
        </Combobox.Button>

        <Combobox.Options>
          {showAssets ? (
            <VirtualizedOptions infiniteQuery={infiniteQuery} />
          ) : showNoResults ? (
            <div className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-950">
              <div className="cursor-default select-none relative py-2 px-3 text-gray-500 dark:text-gray-500">
                No results found
              </div>
            </div>
          ) : null}
        </Combobox.Options>
      </div>
    </Combobox>
  )
}

interface VirtualizedOptionsProps {
  infiniteQuery: UseInfiniteQueryResult<AccountAssetsResponse, Error>
}

function VirtualizedOptions({ infiniteQuery }: VirtualizedOptionsProps) {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = infiniteQuery

  const allRows = data?.pages?.flatMap((page) => page.assets) || []

  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 5
  })

  const virtualRows = rowVirtualizer.getVirtualItems()

  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse()

    if (!lastItem) {
      return
    }

    if (lastItem.index >= allRows.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, fetchNextPage, allRows.length, isFetchingNextPage, virtualRows])

  return (
    <div
      ref={parentRef}
      className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-950"
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative'
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const isLoaderRow = virtualRow.index > allRows.length - 1
          const asset = allRows[virtualRow.index]

          const assetBalance = isLoaderRow ? null : formatAmount(asset.amount)

          const style = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: `${virtualRow.size}px`,
            transform: `translateY(${virtualRow.start}px)`
          } as const

          const className = 'absolute inset-0 cursor-default select-none py-2 pl-3 pr-9'

          return isLoaderRow ? (
            <div
              key={virtualRow.index}
              style={style}
              className={classNames(className, 'px-3 text-gray-500 dark:text-gray-500')}
            >
              Loading more...
            </div>
          ) : (
            <Combobox.Option
              key={virtualRow.index}
              style={style}
              className={({ active }) =>
                classNames(
                  className,
                  'pl-3 pr-9',
                  active ? 'bg-brand-600 text-white' : 'text-gray-900 dark:text-gray-100'
                )
              }
              value={asset}
            >
              {({ active, selected }) => (
                <>
                  <div className="flex items-center">
                    <AssetThumbnail
                      asset={asset}
                      className="relative h-6 w-6 flex-shrink-0 rounded-full overflow-hidden bg-black/5 dark:bg-white/5"
                    />
                    <span className={classNames('ml-3 truncate', selected ? 'font-semibold' : '')}>
                      {asset.name}
                    </span>
                    {assetBalance !== '1' && (
                      <span
                        className={classNames(
                          'ml-2 truncate text-gray-500 font-mono',
                          active ? 'text-brand-200' : 'text-gray-500'
                        )}
                      >
                        {assetBalance}
                      </span>
                    )}
                  </div>

                  {selected && (
                    <span
                      className={classNames(
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                        active ? 'text-white' : 'text-brand-500'
                      )}
                    >
                      <HiCheck className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          )
        })}
      </div>
    </div>
  )
}
